.toolbarButton {
  // stylelint-disable-next-line
  :global .btnIcon {
    margin: 0 6px !important;
  }
}

.refreshBtn {
  display: flex;
  height: 33px;
  padding: 8px 7.5px;
}

.clearIcon {
  cursor: pointer;
}
