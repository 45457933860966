@import 'style/sizes';
@import 'style/colors';

.actionButton {
  width: fit-content;
  margin-left: 25px;
}

.actionItem {
  display: block;
  width: 100%;
  padding: 10px 100px 10px 10px !important;
  clear: both;
  font-size: 13px !important;
  font-weight: $regular;
  text-align: inherit;
  white-space: nowrap;
  cursor: pointer;
  background-color: transparent;
  border: 0;
}

.tableLoadingMore {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  background-color: $secondary-50;
}
